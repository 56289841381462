.card {
    position: relative;
}

.card .obraz {
    height: 200px;
    width: 198px;
    width: 100%;
    display: block;
    border: 2px solid #fff;
    border-radius: 6px;
    cursor: pointer;
}

/* fron of card - the picture */
.card .front {
    transform: rotateY(90deg);
    transition: all ease-in 0.2s;
    position: absolute;
}

.flipped .front {
    transform: rotateY(0deg);
    transition-delay: 0.2s;
}

/* back of card - cover */
.card .back {
    transition: all ease-in 0.2s;
    transition-delay: 0.2s;
}
.flipped .back {
    transform: rotateY(90deg);
    transition-delay: 0s;
}

.card .back {
    height: 200px;
    width: 198px;
    border: 2px solid #fff;
    border-radius: 6px;
    cursor: pointer;
}